<template>
    <div class="banner">
        <a class="back" v-bind:class="{enabled: backEnabled }" v-bind:href="backTo">
            &lt; Back
        </a>
        <div class="banner-1" v-bind:class="{'banner-active':(index === 0)}"></div>
        <div class="banner-2" v-bind:class="{'banner-active':(index === 1)}"></div>
        <div class="banner-3" v-bind:class="{'banner-active':(index === 2)}"></div>
        
    </div>

</template>


<script>

export default {
    name: 'Banner',
	data: function() {
		return {
			index: 0,
            intervalId: "",
            backTo: "/#/music",
            backEnabled: false
		};
	},
    mounted : function(){
        this.switchBanner();     
        this.setBackTo(this.$route.params);
    },
    beforeDestroy () {

       clearInterval(this.intervalId)
    },
    watch:{
        $route (to, from) {
            if (to || from) {
                this.setBackTo(to.params);
            }
        }
    },
    methods: {
        switchBanner (){
            this.intervalId = setInterval(() => {
                this.index = (this.index + 1) % 3;
                //console.log("switch banner to " + this.index);
            }, 14000);
        },
        setBackTo(params){
            if (params.trackIdx){
                this.backTo = "/#/music/" + params.albumIdx;
                this.backEnabled = true;
            }
            else if (params.albumIdx) {
                this.backTo = "/#/music";
                this.backEnabled = true;
            }
            else {
                this.backEnabled = false;
            }
            //console.log("to", to)      
        }
    }
}

</script>

<style>

.back {
    z-index: 9;
    position: absolute;
    bottom: 0px;
    padding: 0.2em 0.6em 0.2em 1.4em;
    border-radius: 0 0.5em 0 0;
    background-color: #60606080;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    font-weight: bold;
    text-shadow: 0 0 5px black;
}

.back.enabled {
    transition-delay: 0.3s;
    opacity: 1;
}

.banner {
    width: 100%;
    height: 184px;
    background-color: black;
    position: relative;
    border: 0 solid #444;
    border-bottom-width: 2px;

}

@media only screen and (min-device-width: 960px) {
    .banner {
        height: 254px;
    }
    .back {
        padding: 0.4em 1em 0.4em 1.5em;
        border-radius: 0 1em 0 0;
    }
}

.banner div {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: opacity 1s ease-in-out;
}

.banner-1 {
    background-image: url("../assets/vubg01.jpg");
    z-index: 1;
    opacity: 0;
}

.banner-2 {
    background-image: url("../assets/vubg02.jpg");
    z-index: 2;
    opacity: 0;
}

.banner-3 {
    background-image: url("../assets/vubg03.jpg");
    z-index: 3;
    opacity: 0;
}

.banner-active {
    opacity: 1;
}


</style>
