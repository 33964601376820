<template>

<main>

<h2 v-if="album">von Uebel - {{ album.title }}</h2>

<div class="grid-container" v-if="album">
   
    <div class="grid-item info">
        <div v-if="album.format">
            {{ album.format }},
            released {{ album.releaseDate | formatDate }}
        </div> 
        <div>
            <h4 v-if="album.format">Tracks:</h4>
            <ol class="tracks">
                <li v-for="(track, trackIdx) in album.tracks" v-bind:key="'track-' + trackIdx">
                    <router-link :to="'/music/' + albumIdx + '/' + trackIdx">{{ track.title }}</router-link>
                    ({{ track.duration }})
                </li>
            </ol>
        </div>

        <div v-if="album.trivia">
            <h4>Trivia:</h4>
            <ul>
                <li v-for="(trivia, triviaIdx) in album.trivia" v-bind:key="'trivia-' + triviaIdx">
                    {{ trivia }}
                </li>
            </ul>
        </div>
    </div>

    <div class="grid-item cover" v-if="album.cover">
        <img :src="require(`@/assets/${album.cover}`)" alt="cover">
    </div>

    <div class="grid-item artwork" v-if="album.artwork">
        <h4>Additional artwork:</h4>
        <img :src="require(`@/assets/${album.artwork}`)" alt="artwork">
    </div>


</div>

</main>


</template>


<script>

import Albums from '../assets/music.json'

const months = ["January", "February", "March", "April", "May", "June", "July", 
                "August", "September", "October", "November", "December"];

export default {
    name: 'Album',

	data: function() {
		return {
            albumIdx: -1,
			album: null
		};
	},
    mounted() {
        this.albumIdx = this.$route.params.albumIdx;
        if (this.albumIdx >= Albums.length) 
        {
            console.log("invalid albumIdx");
        } else {
            this.album = Albums[this.albumIdx];
            document.title = "von Uebel - " + this.album.title;
        }
    },
    filters: {
        formatDate(val){
            let dateStr = val;
            if (dateStr) {
                const dt = new Date(val)
                const month = months[dt.getMonth()];
                const day = dt.getDate();
                const year = dt.getFullYear();
                if (month && day && year){
                    dateStr = month + " " + day + ", " + year;
                } 
            }
            return dateStr;
        }    
    },
    methods:{
    }
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.grid-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
        "cover"
        "info"
        "artwork";
    column-gap: 1em;
    row-gap: 1em;
}


.grid-item.cover {
    grid-area: cover;
}

.grid-item.info {
    grid-area: info;
}

.grid-item.info > div {
    margin-bottom: 1em;
}

.grid-item.artwork {
    grid-area: artwork;
}

.grid-item img {
    width:100%;
    box-shadow: 2px 4px 4px 2px #00000040;
}

.grid-item h4 {
    margin-top: 0;
    margin-bottom: 0.2em;
}

.grid-item p {
    margin-top: 0.2em;
}



@media only screen and (min-device-width: 960px) {

    .grid-container {
        grid-template-columns: 50% auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "info cover"
            "info artwork";
    }

}

</style>

