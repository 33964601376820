var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_vm._m(0),_c('nav',[_c('div'),_c('ul',[_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'router-link-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Home")])])]}}])}),_c('router-link',{attrs:{"to":"/music"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Music")])])]}}])}),_c('router-link',{attrs:{"to":"/about"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("About")])])]}}])})],1),_c('div')])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/vulogo.png"),"alt":"vonUebel Logo"}})])])}]

export { render, staticRenderFns }