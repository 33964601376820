<template>

<main>

<div>
    <div class="card">

        <div class="card-title">
            <h3>von Uebel - Bloom released!</h3>
        </div>
     
        <div class="card-image">
            <img src="../assets/cover_bloom.jpg" />
        </div>

        <div class="card-subtitle">May 4, 2021</div>

        <div class="card-info">
            <p>
            <span class="ital">Bloom</span>, the new album of <span class="ital">von Uebel</span> has been released today, offering an indecisive staggering between
            metal, industrial, gothic and randomly assorted clichés.
            </p>
            <p>
            The album features 9 tracks with a total run time of about 56 minutes and is available on digital music stores and streaming providers. 
            </p>
            <p>
            <ul>
            <li>
                <a href="https://open.spotify.com/album/0tSLjiwmn9cNkkUP4WoE2o">Spotify</a><br>
            </li><li>
                <a href="https://www.youtube.com/watch?v=-KrMvU6TDUQ&list=PL0zvqNRZatNDnBnTCih34ApuNZfRWDTHd&ab_channel=VariousArtists-Topic">Youtube</a>
            </li><li>
                <a href="https://www.amazon.de/gp/product/B091C38L8D">Amazon Mp3</a>
            </li><li>
                <a href="https://music.amazon.com/albums/B091BQ8BGY">Amazon Music</a>
            </li><li>
                <a href="https://music.apple.com/us/album/bloom/1561323673">Apple Music</a>
            </li><li>
                <a href="https://www.deezer.com/us/album/218937372">Deezer</a>
            </li></ul>
            </p>
        </div>

        <div class="card-info">
            <p> Or find me in the Fediverse at
                <a rel="me" href="https://metalverse.social/@vonuebel">Mastodon</a>
            </p>
        </div>

    </div>

</div>



</main>

</template>


<script>

export default {
    name: 'Home',
    mounted() {
        document.title = "von Uebel - Home";
    },
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.card {
    margin: 0.5em 0 0 0;
    padding: 0.5em 0.5em 0 0.5em;

    border: 15px solid transparent;
    border-image: url("../assets/vucard.png") 15;

    clear: both;
    overflow: hidden;

}

h3 {
    margin: 0;
    margin-bottom: 0.5em;
}

.card-title {

}

.card-subtitle {
    font-style: italic;
}

.card-image {
    float: right;
    max-width: 42%;
    margin-right: 0.5em;
}

.card-image img {
    width: 100%;
    margin: 0 0 0.5em 0.5em;
    box-shadow: 2px 4px 4px 2px #00000040;
}

.card-info {

}

.bold {
    font-weight: bold;
}

.ital {
    font-style: italic;
}


@media only screen and (min-device-width: 960px) {

    .card-image {
        float: right;
        max-width: 42%;
        margin-right: 2em;
    }

    .card-image img {
        margin: 0 1em 1em 2em;
    }
}


</style>
