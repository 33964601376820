<template>

<main>

<h2 v-if="track">von Uebel - {{ track.title }}</h2>

<div class="grid-container" v-if="track">
   
    <div class="grid-item info">
        <div class="grid-item label">
            <h4>Album:</h4>
        </div> 

        <div class="grid-item value">
            <router-link :to="'/music/' + albumIdx">{{ album.title }}</router-link>
        </div> 

        <div v-if="album.format" class="grid-item label">
            <h4>Track No:</h4>
        </div>

        <div v-if="album.format" class="grid-item value">
            {{ getTrackNo(trackIdx) }}
        </div>

       <div class="grid-item label">
            <h4>Duration:</h4>
        </div>

        <div class="grid-item value">
            {{ track.duration }}
        </div>

        <div class="grid-item label">
            <h4>Music by:</h4>
        </div>

        <div class="grid-item value">
            {{ composer }}
        </div>

        <div v-if="lyricist" class="grid-item label">
            <h4>Lyrics by:</h4>
        </div>

        <div v-if="lyricist" class="grid-item value">
            {{ lyricist }}
        </div>
    
    </div>

    <div class="grid-item lyrics" v-if="track.lyrics">
        <h4>Lyrics:</h4>
        <p>
            {{ track.lyrics | formatLyrics }}
        </p>
    </div>

    <div class="grid-item trivia" v-if="track.trivia">
        <h4>Trivia:</h4>
        <ul>
            <li v-for="(trivia, triviaIdx) in track.trivia" v-bind:key="'trivia-' + triviaIdx">
                {{ trivia }}
            </li>
        </ul>
    </div>

    <div class="grid-item artwork" v-if="track.artwork">
        <h4>Additional artwork:</h4>
        <img :src="require(`@/assets/${track.artwork}`)" alt="artwork">
    </div>
    <div class="grid-item artwork" v-else-if="album.cover">
        <h4>Album cover:</h4>
        <img :src="require(`@/assets/${album.cover}`)" alt="artwork">
    </div>

</div>

</main>


</template>


<script>

import Albums from '../assets/music.json'

export default {
    name: 'Album',

	data: function() {
		return {
            albumIdx: -1,
            trackIdx: -1,
			album: null,
            track: null,
            composer: "Urs von Uebel",
            lyricist: "Urs von Uebel",
		};
	},
    mounted() {
        this.albumIdx = parseInt(this.$route.params.albumIdx);
        this.trackIdx = parseInt(this.$route.params.trackIdx);
        if (this.albumIdx >= Albums.length) 
        {
            console.log("invalid albumIdx");
        } else {
            this.album = Albums[this.albumIdx];
            console.log("album", this.album);
            if (this.trackIdx >= this.album.tracks.length) 
            {
                console.log("invalid trackIdx");
            } else {
                this.track = this.album.tracks[this.trackIdx];
                document.title = "von Uebel - " + this.track.title;
                if (this.track.composer !== undefined) { this.composer = this.track.composer; }
                if (this.track.lyricist !== undefined) { this.lyricist = this.track.lyricist; }
            }
        }
    },
    filters: {
        formatLyrics: function (arr) {
            return arr.join("\n");
        }
    },
    methods:{
        getTrackNo(val) {
            return val + 1;
        }
    }
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.lyrics p {
    list-style-type: none;
    white-space: pre-line;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: 
        "info"
        "artwork"
        "lyrics"
        "trivia";
    column-gap: 0em;
    grid-row-gap: 0em;
}

.grid-item.info {
    grid-area: info;
    display: grid;
    grid-template-columns: 5em auto;
    grid-template-rows: auto;
    column-gap: 1em;
    grid-row-gap: 0em;
    margin-top: 0;
}

/*
.grid-item.info > div {
    margin-bottom: 1em;
}
*/

.grid-item.artwork {
    margin-top: 1em;
    grid-area: artwork;
}

.grid-item img {
    width:100%;
    box-shadow: 2px 4px 4px 2px #00000040;
    margin-top: 0.2em;
}

.grid-item h4 {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}

.grid-item p {
    margin: 0.2em 0 0 0;
}

.grid-item.label {
}

.grid-item.value {
    margin-top: 0.2em;

}


.grid-item.lyrics {
    margin-top: 1em;
    grid-area: lyrics;
}

.grid-item.trivia {
    margin-top: 1em;
    grid-area: trivia;
}


@media only screen and (min-device-width: 960px) {

    .grid-container {
        grid-template-columns:  50% auto;
        grid-template-areas: 
            "info artwork"
            "lyrics artwork"
            "trivia artwork";
    }

    .grid-item.artwork {
        margin-top: 0;
        margin-left: 1em;
    }
}

</style>

