import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import App from './App.vue'

Vue.config.productionTip = false

import Home from './components/Home.vue'
import Music from './components/Music.vue'
import Album from './components/Album.vue'
import Track from './components/Track.vue'
import About from './components/About.vue'
import Impressum from './components/Impressum.vue'
import Datenschutz from './components/Datenschutz.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/home', component: Home },
    { path: '/music', component: Music },
    { path: '/music/:albumIdx', component: Album },
    { path: '/music/:albumIdx/:trackIdx', component: Track },
    { path: '/impressum', component: Impressum },
    { path: '/datenschutz', component: Datenschutz },
    { path: '/about', component: About }
]

const router = new VueRouter({
    routes, // short for `routes: routes`
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})

/* create sitemap.xml */
/*
function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}


function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://vonuebel.com')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

console.log(getRoutesXML());
*/

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
