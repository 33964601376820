<template>
    <div id="app">

        <Header></Header>
        <Banner></Banner>

        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>

        <Footer></Footer>

    </div>
</template>

<script>

import Header from './components/Header.vue'
import Banner from './components/Banner.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    components:{
        Header,
        Banner,
        Footer
    }
}
</script>

<style>

html {
    color: white;
    background-color: #111;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    background-color: #111;
    background: #111;
    background: linear-gradient(180deg, #333 0%, #111 100%);
    background-repeat: no-repeat;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em;  margin: 0.75em 0 0.75em 0; }
a, p, li, div { font-size: 1em; color: white; }


a {
    text-decoration: none;
    font-weight: bold;
}

a:link {
    text-decoration: none;
}

a:visited {

}

a:focus {
    outline: none;
    text-decoration: underline;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
}

a.buy {
    display: inline-block;
    text-align: center;
    color: white;
    text-decoration: none;
    background-color: #447100;
    border: 0px solid #909090;
    border-radius: 5px;
    padding: 0.3em;
    font-size: 0.8em;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
}

#app {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    border: 0 solid black;
    background-color: #282828;
}

main {
    background-color: #282828;
    padding: 0 1.5em 1em 1.5em;
    font-size: 100%;
}

ol, ul {
    list-style-position: inside;
    padding: 0;
    margin: 0;
}

ol {
    list-style-type: decimal;
    
}

ol.tracks li {
    margin-bottom: 0.2em;
}

ul {
    list-style-type: square;
}

/* transitions */
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  transition: opacity .3s ease-in-out;
}
.fade-leave-active {
  transition: opacity .3s ease-in-out
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


/* css media handling */

@media only screen and (min-device-width: 960px) {
    #app {
        width: 960px; 
        border: 1px solid #111;
        border-top-width: 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    main {
        font-size: 120%;
    }
}



</style>
