<template>

    <header>
        <h1>
            <a href="/">
                <img src="@/assets/vulogo.png" alt="vonUebel Logo" />
            </a>
        </h1>

        <nav>
            <div></div>
            <ul>
                <!--
                <router-link to="/" exact tag="li">Home</router-link>
                <router-link to="/music" tag="li">Music</router-link>
                <router-link to="/about" exact tag="li">About</router-link>
                -->

                <router-link
                  to="/"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <li
                    :class="[isExactActive && 'router-link-active']"
                  >
                    <a :href="href" @click="navigate">Home</a>
                  </li>
                </router-link>
                
                <router-link
                  to="/music"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <li
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                  >
                    <a :href="href" @click="navigate">Music</a>
                  </li>
                </router-link>

                <router-link
                  to="/about"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <li
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                  >
                    <a :href="href" @click="navigate">About</a>
                  </li>
                </router-link>

            </ul>
            <div></div>
        </nav>
    </header>

</template>


<script>

export default {
  name: 'Header'
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

header {
    position: fixed;
    width: 100%;
    display: flex;
    z-index: 100;
    box-shadow: 0 0 8px 0 #00000040, 0 0 16px 0 #00000020;
}


header h1 {
    background-color: black;
    margin: 0;
    padding: 0;
    border: 0 solid #6860cd;
    border-bottom-width: 4px;
}


header h1 a {
    padding: 0;
    margin: 0;
}

header h1 a img {
    padding: 0;
    width: 100px;
    height: 42px;
    padding: 0px 6px 2px 10px;
    vertical-align: middle;
}


header nav {
    display: flex;
    flex: 1 1 50%;
    padding: 0;
}

header div {
    flex: 1 1 0px;

    margin: 0;
    padding: 0;

    background-color: black;
    border: 0 solid #6860cd;
    border-bottom-width: 4px;

}

nav ul {
    flex: 1 1 50%;
    display: flex;
    align-items: stretch;

    padding: 0;
    margin: 0;

    list-style: none;
}



@keyframes from-active {
  from {
    margin-bottom: -8px;
    font-size: 3.2vw;
    background-color: #282828;
    box-shadow: 0 6px 6px 6px #00000040, 0 12px 12px 12px #00000020;
  }
  to {
  
  }
}

@keyframes to-active {
  from {
    margin-bottom: 0px;
    font-size: 2.8vw;
    background-color: black;
    box-shadow: 0 0px 0px 0px #00000000, 0 0px 0px 0px #00000000;
  }
  to {
    font-size: 3.2vw;
    background-color: #282828;
    box-shadow: 0 6px 6px 6px #00000040, 0 12px 12px 12px #00000020;
  
  }
}

@keyframes from-active-large {
  from {
    margin-bottom: -8px;
    font-size: 2.0vw;
    background-color: #282828;
    box-shadow: 0 6px 6px 6px #00000040, 0 12px 12px 12px #00000020;
  }
  to {
  
  }
}

@keyframes to-active-large {
  from {
    margin-bottom: 0px;
    font-size: 1.5vw;
    background-color: black;
    box-shadow: 0 0px 0px 0px #00000000, 0 0px 0px 0px #00000000;
  }
  to {
    font-size: 2.0vw;
    background-color: #282828;
    box-shadow: 0 6px 6px 6px #00000040, 0 12px 12px 12px #00000020;
  
  }
}

nav li {
    flex: 1 1 33%;
   
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;
    
    border: 0 solid #6860cd;
    border-bottom-width: 4px;

    font-size: 3.2vw;
    text-shadow: 0 0 5px black;

    text-decoration: none;
    font-weight: bold;

    z-index: 11;

    /* transitions */
    transition: all 300ms ease;
    transition-property: background-color, margin-bottom, font-size, box-shadow;

}

nav li a {
    text-align: center;
    line-height: 42px;
    padding: 0;
    height:100%;
    width: 100%;

    /* transitions */
    transition: all 300ms ease;
    transition-property: padding-top;
}


li.router-link-active {
    flex: 1 1 33%;
    margin-bottom: -8px;
    
    background-color: #282828;
    /*
    background: radial-gradient(closest-side, #444 0%, #282828 100%);
    */

    box-shadow: 0 0 10px 10px #00000040, 0 0 20px 15px #00000020;
    font-size: 3.6vw;
    
    z-index: 12;

}

li.router-link-active a {
    padding-top: 8px;
}


@media only screen and (min-device-width: 960px) {

    header {
        width: 960px;
    }

    header h1 a img {
        padding: 0;
        width: 200px;
        height: 84px;
        padding: 6px 6px 2px 10px;
        vertical-align: middle;
    }

    nav ul {
        flex: 1 1 30%;
    }

    nav li {
        font-size: 1.5em;
        animation-name: from-active-large;
    }

    nav li a {
        text-align: center;
        line-height: 84px;
        height:100%;
        width: 100%;
    }

    li.router-link-active {
        font-size: 1.9em;
        animation-name: to-active-large;
    }

    li.router-link-active a {
        padding-top: 8px;
    }

}


</style>
