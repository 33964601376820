<template>

<main>

<h2> 
    Music
</h2>


<div class="grid-container grid-cards">

    <div class="card" v-for="(album, albumIdx) in albums" :key="albumIdx">

        <div class="card-image cover" v-if="album.cover">
            <router-link :to="'/music/' + albumIdx">
                <img :src="require(`@/assets/${album.cover}`)" alt="cover">
            </router-link>
        </div>

        <div class="card-title">
            <h3><router-link :to="'/music/' + albumIdx">{{ album.title }}</router-link></h3>
        </div>

        <div class="card-subtitle" v-if="album.format !== null && album.releaseDate !== null">
            {{ album.format }},
            {{ getYear(album.releaseDate) }}
        </div>

        <div class="card-text">
            <ol class="tracks">
                <li v-for="(track, trackIdx) in album.tracks" :key="albumIdx + '-' + trackIdx">
                    <router-link :to="'/music/' + albumIdx + '/' + trackIdx">{{ track.title }}</router-link>
                </li>
            </ol>
        </div>
        
    </div>

</div>



</main>

</template>


<script>

import Albums from '../assets/music.json'

export default {
    name: 'Music',
	data: function() {
		return {
			albums: Albums
		};
	},
    mounted() {
        document.title = "von Uebel - Music";
    },
    methods:{
        getYear(val) {
            const dt = new Date(val);
            const year = dt.getFullYear();
            if (year){
                return year;
            } else {
                return val;
            }
        }
    },

}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.released { font-style: italic; }

.grid-container {
    display: grid;
    grid-template-columns: auto;
}

.grid-cards {
    grid-gap: 1em;
}

@media only screen and (min-device-width: 960px) {

    .grid-container {
        grid-template-columns: auto auto;
    }

    .grid-cards {
        grid-template-columns: repeat(2, 1fr);
    }

}

.card {
    padding: 0.5em 0.5em 0 0.5em;

    border: 15px solid transparent;
    border-image: url("../assets/vucard.png") 15;

}

.card-title {
    margin-bottom: 0.5em;
}

h3 {
    margin: 0;
}


.card-subtitle {
    font-style: italic;
    margin-bottom: 0.5em;
}

.card-image {
    margin-left: 0.5em;
    width:50%;
    margin-bottom: 0.5em;
}

.cover {
    float: right;
}

.card-image img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 2px 4px 4px 2px #00000040;
}

.card-text {
    padding: 0.4em 0;
}





</style>
