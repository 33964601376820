<template>

<main>

<h2>
    About 
</h2>
<p>

<img src="../assets/vupic.jpg" />
Since 1994 von Uebel mostly fails at bolting random musical ideas and genres to a
Metal framework, cutting fragile melodies from instable harmonies and sinking
massive rythmic structures to obstruct inland waterways. Lyrics are present and
their presentation varied, a garbled mess drowning the underlying grand scheme.
</p>
<p>
von Uebel uses GNU/Linux, Ardour, Pure Data, Jack   
and non-exclusively other software and gear from manufacturers that do not sponsor von Uebel.
</p>
<p>
This site was made with Vim, Vue, the GNU Image Manipulation Program and Inkscape.
</p>

</main>

</template>


<script>

export default {
    name: 'About',
    mounted() {
        document.title = "von Uebel - About";
    },
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

main {
    overflow: hidden;
}

img {
    float: right;
    margin: 0 0 1em 0.5em;
}

@media only screen and (min-device-width: 960px) {
    img {
        margin: 0 1em 2em 2em;
    }
}

</style>
