<template>

    <footer>
        &copy; U. von Uebel 2020-2022
        <a href="#/impressum">Impressum</a>
        <a href="#/datenschutz">Datenschutz</a>
    </footer>

</template>


<script>

export default {
  name: 'Footer'
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

footer {
    clear: both;
    display: flex;
    justify-content: center;
    padding: 0.5em 1em;
    font-size: 80%;
    background-color: #282828;
    color: #bbb;
    border: 0 solid #444;
    border-top-width: 1px;
}

a {
    margin-left: 1.5em;
    color: #bbb;
}

</style>

